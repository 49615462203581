<template>
  <div class="container d-flex justify-content-center">
    <div class="d-flex flex-wrap">
      <!-- <div>
        <b-button v-b-modal.modal-1>Launch demo modal</b-button>

        <b-modal id="modal-1" title="BootstrapVue">
          <p class="my-4">Hello from modal!</p>
        </b-modal>
      </div> -->
      <div v-for="(item,index) in imageArr" :key="index" class="custom-col">
        <b-button
          v-b-modal.modal-1
          class="card mr-5"
          style="width: 260px; height:140px"
          ok-only
          @click="onClickSpecCard(item)"
        >
          <div class="row g-0">
            <div
              class="col-md-4"
              style="
                display: flex;
                align-items: center;
                justify-contents: center;
                height:150px;
              
              "
            >
              <img
                :src="item"
                class="img-fluid rounded-start"
                alt="..."
                style="
                  height: 100px;
                  object-fit: cover;
                  margin-left: 8px;
                  border-radius: 5px;
                "
              />
            </div>
            <div class="col-md-8" :style="{ height: '100%' }">
              <ul class="card-body">
                 <div class="card-text"><span class="card-title">CHILLER</span></div>
                <li class="card-text">
                  <small class="smallTitle">제조사</small>
                  <span class="real-card-text">삼성</span>
                  </li>
                <li class="card-text">
                  <small class="smallTitle">
                    전압</small>
                    <span class="real-card-text">6V</span>
                    </li>
                <li class="card-text"><small class="smallTitle">검사일</small>
                  <span class="real-card-text">2024/06/06</span>
                  </li>
              </ul>
            </div>
          </div>
        </b-button>
      </div>
      <b-modal id="modal-1" title="설비 스펙 이미지">
        <div class="specImgModal">
          <img :src="modalImg" alt="이미지" class="modalImg" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageArr:[ "https://picsum.photos/600/300/?image=25", "https://picsum.photos/600/300/?image=20", "https://picsum.photos/600/300/?image=21" ,"https://picsum.photos/600/300/?image=22" ,"https://picsum.photos/600/300/?image=23", "https://picsum.photos/600/300/?image=24"],
      modalImg:""
    };
  },

  methods: {
    onClickSpecCard(image) {
      this.modalImg = image;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  margin-left: 9px;

}
.smallTitle{
  width:35px;
  display: flex;
  justify-content: start;
}
.card {
  /* https://getcssscan.com/css-box-shadow-examples 에서 가져옴 */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  cursor: pointer;
  background: white;
  color: black;
  border-radius: 5px;
}
.card-title{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  color: black;
}
.card-text{
  display: flex;
  margin-bottom: 10px;
  align-items:flex-start;
  color:#616161;
  line-height: 6px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
/* .real-card-text{
  line-height: 5px;
} */
.custom-col {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.modalImg {
  width: 400px;
}
.specImgModal {
  display: flex;
  justify-content: center;
}
</style>
