<template>
    <div class="container">
        <div class="row flex-grow-0 mb-2">
            <div class="col">
                <!-- TableList -->
                <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="detailInfo.ioProps"
                    :keyField="'propCode'" :transCodes="transCodes" :controls="controls" :mode="mode" :useMaxHeight="true"
                    :toggleFields="['enabled']" @button-click=" handleListButtonClick" :useFilter="false"
                    @row-click="handleListItemClick" @toggle-changed="handleItemToggle" />
        
                <!-- InPropDetail Popup -->
                <b-modal ref="IoPropDetailModal" size="lg" centered @hidden="closeIoPropModal">
                    <template #modal-header>
                        <h5>{{ tempIoPropIndex !== null || undefined ? '계측 속성 편집' : '계측 속성 추가' }}</h5>
                    </template>
                    <template #modal-footer centered>
                        <button v-show="isEditMode" type="button" class="btn btn-lg btn-primary" @click="handleAddListItem">저장</button>
                        <button type="button" class="btn btn-lg btn-secondary" @click="closeIoPropModal">닫기</button>
                    </template>
        
                    <IoPropDetailPanel ref="IoPropDetailPanel"
                        :connDriver="{ driverType: detailInfo.driverType, version: detailInfo.version }"
                        :mode="mode" />
        
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import IoPropDetailPanel from './IoPropDetailPanel.vue';

export default {
    components: {  
        TableListComponent,
        IoPropDetailPanel,
    },
    props: [ 'detailInfo', 'mode' ],
    created() {
        console.log('InPropListPanel.created() called')
    },
    mounted() {
        console.log('InPropListPanel.mounted() called')
    },
    data() {
        return {
            controls: [
                {event: 'delete', label: '-', class: 'btn-danger'},
                {event: 'regist', label: '+', class: 'btn-warning'},
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'ioType',
                    codes: this.$store.state.commonCodes.ioType,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('속성 코드명'),
                    field: 'propName',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('입출력 구분'),
                    field: 'ioType',
                    type: 'text',
                },
                {
                    label: this.$t('단위 유형'),
                    field: 'unitType',
                    type: 'text',
                },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                },
                {
                    label: this.$t('사용 여부'),
                    field: 'enabled',
                    type: 'text',
                    width: '80px',

                }
            ],
            tempIoPropIndex: null,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },        
    },
    watch: {
        detailInfo() {
            console.log('WATCH InPropListPanel detailInfo is changed:', this.detailInfo);
        }
    },
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;

            console.log(`handleListButtonClick(${event}). options:`, options);

            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            if(toggle || check) return;
            console.log('handleListItemClick. row:', row);
            console.log('handleListItemClick. index:', index);
            console.log("detailInfo.ioProps : ", this.detailInfo.ioProps)
            this.tempIoPropIndex = index;
            let findIoProp = this.detailInfo.ioProps.find(item => item.propCode == row.propCode)
            await this.$refs.IoPropDetailModal.show();
            await this.$refs.IoPropDetailPanel.setData(findIoProp);
        },
        closeIoPropModal() {
            const that = this;
            if(this.tempIoPropIndex !== null || undefined) this.tempIoPropIndex = null;
            that.$refs.IoPropDetailModal.hide()
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async (item) => {
                        console.log('deleteChecked. equipType :', item.propCode);
                        // await backEndApi.propCodes.deleteEquipType(item.propCode);
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.ioProps = that.detailInfo.ioProps.filter(prop => {
                            return !checked.find(checkedProp => checkedProp.propCode === prop.propCode);
                        })
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async registNew() {
            const that = this;
            console.log('Regist New One :', that.inPropBuffer);
            that.tempIoProp = null;
            
            that.$refs.IoPropDetailModal.show();
            that.$nextTick(() => {
                that.$refs.IoPropDetailPanel.setData(null);
            })
        },        
        handleAddListItem() {
            console.log('handleAddListItem : ioProps: ', this.detailInfo.ioProps);
            console.log("handleAddListItem this.tempIoPropIndex", this.tempIoPropIndex);
            const ioProp = this.$refs.IoPropDetailPanel.getData();

            if(ioProp){
                console.log("handleAddListItem this.tempIoPropIndex", this.tempIoPropIndex);
                if(this.tempIoPropIndex !== null || undefined) {
                    console.log("edit Props")
                    this.$set(this.detailInfo.ioProps, this.tempIoPropIndex, ioProp);
                    this.tempIoPropIndex = null;
                } else {
                    console.log("new Props")
                    // Check Duplication (propCode, propName)
                    let check = this.detailInfo.ioProps.find(prop => prop.propCode === ioProp.propCode || prop.propName === ioProp.propName);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                        console.error(`In Property is already exists in ioProps. propCode:${ioProp.propCode}, propName:${ioProp.propName}`);
                    } else {
                        this.$set(this.detailInfo.ioProps, this.detailInfo.ioProps.length, ioProp);
                    }
                }
            }
            console.log('handleAddListItem : ioProps: ', this.detailInfo.ioProps);

            this.$refs.IoPropDetailModal.hide();
        },
        handleItemToggle(key, field, status) {
            console.log("ioProp toggle change key : ", key)
            console.log("ioProp toggle change field : ", field)
            console.log("ioProp toggle change status : ", status)

            const that = this;
            const found = that.detailInfo.ioProps.find(item => item.propCode === key)
            found.enabled = status
        },
    },
}
</script>

<style scoped>

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}

</style>