<template>
    <div class="detail-container">
        <div class="detail-content">
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-4">
                    <label for="propCode">사용자 정의 속성 코드 :</label>
                    <input v-model="detailInfo.propCode" type="text" class="form-control" id="propCode" :disabled="!isEditMode">
                </div>
                <div class="form-group col-md-4">
                    <label for="propName">사용자 정의 속성명 :</label>
                    <input v-model="detailInfo.propName" type="text" class="form-control" :disabled="!isEditMode"
                        id="propName">
                </div>
                <div class="form-group col-md-4">
                    <label for="enabled">사용 여부 :</label>
                    <select v-model="detailInfo.enabled" id="enabled" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-4">
                    <label for="representSumYn">대표값 집계 여부 :</label>
                    <select v-model="detailInfo.representSumYn" id="representSumYn" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label for="dataSourceType">데이터 생성 방식 :</label>
                    <select v-model="detailInfo.dataSourceType" id="dataSourceType" class="form-control" disabled>
                        <option :value="null">선택</option>
                        <option v-for="(code) in $store.state.commonCodes.dataSourceType" :key="code.value"
                            :value="code.value" id="dataSourceType">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label for="ptType">집계 유형 :</label>
                    <select v-model="detailInfo.ptType" id="ptType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(code) in $store.state.commonCodes.ptTypeCode" :key="code.value"
                            :value="code.value" id="ptType">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-4">
                    <label for="unitType">단위 유형 :</label>
                    <select v-model="detailInfo.unitType" id="unitType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(value) in $store.state.unitTypes" :key="value.value" :value="value.value"
                            id="unitType">
                            {{ value.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label for="unit">단위 :</label>
                    <select v-model="detailInfo.unit" id="unit" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(code) in $store.state.unitCodes[detailInfo.unitType]" :key="code.value"
                            :value="code.value" id="unit">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label for="encodeType">표시 등급 :</label>
                    <select v-model="detailInfo.dispLevel" id="unit" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(code) in $store.state.commonCodes.dispLevel" :key="code.value"
                            :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-12">
                    <label for="encodeDesc">사용자 정의값 :</label>
                    <textarea v-model="detailInfo.userValue" type="text" class="form-control" id="encodeDesc"
                        :disabled="!isEditMode" rows="2" style="resize: none;"></textarea>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-12">
                    <label for="description">설명 :</label>
                    <textarea v-model="detailInfo.description" type="text" class="form-control" id="description"
                        :disabled="!isEditMode" rows="2" style="resize: none;"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";

export default {
    components: {},
    props: ['mode'],
    data() {
        return {
            detailInfo: null,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    created() {
        this.detailInfo = this.initData();
    },
    mounted() {
    },
    destroyed() { },
    methods: {        
        initData() {
            return {
                propCode : '',
                propName: '',
                dataSourceType: 'User',
                ioType : '',
                ptType: '',
                unitType : '',
                unit : '',
                scale : 0,
                encodeType : '',
                encodeDesc : '',
                description: '',
                dispLevel: '0',
                enabled: 'Y',
                representSumYn: 'N',
            }
        },
        setData(data) {
            if (data) {
                // this.mode = 'view';
                this.detailInfo = data;
            } else {
                // this.mode = 'edit';
                this.detailInfo = this.initData();
            }
        },
        getData() {
            return this.detailInfo;
        },
    },}
</script>

<style scoped>
.detail-container {    
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    margin: 1rem;
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {    
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

</style>